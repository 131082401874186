<template>
  <!-- <div :class="[flag? '': 'content-box']"> -->
  <!-- <div :class="[flag? '': 'container']"> -->
  <div class="content" style="padding-left: 15px">
    <div class="content_row flex">
      <label class="info" for=""> 公司名称</label>
      <div class="content_right">
        <a-input v-model="data.company_name" size="large"/>
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">联系电话</label>
      <div class="content_right">
        <a-input v-model="data.company_tel" size="large"/>
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">邮箱</label>
      <div class="content_right">
        <a-input v-model="data.company_email" size="large"/>
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">地址</label>
      <div class="content_right">
        <a-input v-model="data.company_address" size="large"/>
      </div>
    </div>
    <div class="content_row flex" >
        <label class="info" for="">有奖推荐规则</label>
        <div class="content_right">
            <a-textarea v-model="data.clubrule" placeholder="请输入推荐规则" :auto-size="{ minRows: 5, maxRows: 8 }"/>
        </div>
    </div>
    <div class="save">
      <a-button type="primary" @click="submit">保存</a-button>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
<script>
// import $ from "jquery";
import { isMobile, ajaxUrl, requestXml } from "../../../assets/js/request";
import moment from "moment";
export default {
  props: {
    addFlag: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: String,
    },
  },
  data() {
    return {
      flag: true,
      ajaxUrl: ajaxUrl,
      data: {
        company_name: "",
        company_tel: "",
        company_email: "",
        company_address: "",
      },
    };
  },
  mounted: function () {
    // this.id = this.$route.query.id;
    this.getDetail();
    if (isMobile()) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  },

  methods: {
    moment,
    submit() {
      let url = "/jieb/Apisetting/informationedit";
      requestXml(
        url,
        "POST",
        (status) => {
          console.log(status);
          this.getDetail();
        },
        this.data
      );
    },
    // 获取详情
    getDetail() {
      requestXml(
        "/jieb/Apisetting/informationshow",
        "GET",
        (res) => {
          this.data = Object.assign({}, res);
        }
      );
    },
  },
};
</script>
<style scoped>
.action {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background: #dceeff;
  border: 1px solid #36a3ff;
  color: #36a3ff;
  border-radius: 5px;
}
.del {
  background: #ffdcdc;
  border-color: #ff4c4c;
  color: #ff4c4c;
}
.content .content_row {
  align-items: center;
  margin: 2rem 0;
}
.content_right {
  width: calc(100% - 120px);
  text-align: left;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #36a3ff;
  border-color: #36a3ff;
}
.content .content_row .info {
  display: inline-block;
  width: 15rem;
  text-align: right;
  padding-right: 1rem;
  color: #202020;
  font-weight: normal;
}
.content .content_row .fileLabel {
  line-height: 1.499;
  font-weight: 400;
  background-image: none;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
}
.content .content_row input[type="file"] {
  display: none;
}
/* 输入框 下拉框 */
.content .content_row input,
.content .content_row .ant-select {
  width: 100%;
  /* background-color: #f9f9f9; */
}
.content .content_row .ant-select-selection--multiple input {
  width: 0;
}
/* 日期 */
.content .content_row .ant-calendar-picker {
  width: 100% !important;
}
.content .content_row .ant-calendar-picker input {
  width: 100%;
}

.save {
  text-align: left;
}
.save .ant-btn {
  width: 100px;
  height: 40px;
  background: #36a3ff;
  border-radius: 4px;
  margin-left: 13rem;
}

@media screen and (min-width: 750px) {
  .content .content_row {
    margin: 15px 0;
    font-size: 16px;
  }
  .content .content_row .info {
    width: 90px;
    padding-right: 10px;
  }
  .content .content_row .ant-calendar-picker {
    width: 400px !important;
  }
  .content .content_row .ant-calendar-picker input {
    width: 100% !important;
  }
  /* input ,selsct */
  .content .content_row input,
  .content .content_row .ant-select,
  .content .content_row textarea {
    width: 400px;
  }
  .save .ant-btn {
    margin-left: 90px;
  }
}
</style>
